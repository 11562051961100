<template>
  <v-app>
    <v-app-bar app color="white" light dense>
      <a href="/">
        <v-img
          alt="logo"
          class="shrink mr-2"
          contain
          src="/images/doa_logo_full.svg"
          transition="scale-transition"
      /></a>

      <v-tabs center-active v-if="!$store.state.currentUser">
        <v-tab to="/login">Login</v-tab>
        <v-tab to="/signup">Signup</v-tab>
        <v-tab to="/password/recover">Account Recovery</v-tab>
      </v-tabs>

      <v-tabs
        center-active
        show-arrows
        v-else-if="
          $store.state.currentUser.role == 'admin' ||
          $store.state.currentUser.role == 'owner'
        "
      >
        <v-tab to="/admin/users">Users</v-tab>
        <v-tab to="/admin/applications">Applications</v-tab>
        <!-- <v-tab to="/admin/schedules">Schedules</v-tab> -->
      </v-tabs>

      <v-tabs
        center-active
        v-else-if="$store.state.currentUser.role == 'professor'"
      >
        <v-tab to="/review/applications">Applications</v-tab>
        <!-- <v-tab to="/review/schedule">Schedule</v-tab> -->
      </v-tabs>

      <v-tabs
        center-active
        v-else-if="$store.state.currentUser.role == 'applicant'"
      >
        <v-tab to="/application/info">Apply</v-tab>
        <!-- <v-tab to="/application/schedule">Schedule</v-tab> -->
      </v-tabs>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <v-footer dark padless>
      <v-card flat tile class="flex white--text text-center">
        <v-list color="primary" v-if="$store.state.currentUser">
          <v-list-item>
            <v-list-item-avatar>
              <img
                :src="$store.state.currentUser.avatar"
                :alt="$store.state.currentUser.realname"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                v-text="$store.state.currentUser.realname"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="$store.state.currentUser.role"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="handleLogout">
                <v-icon>mdi-logout</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>DoA</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import usersService from "@/services";

export default {
  name: "App",
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    handleLogout() {
      usersService.logout();
      this.$store.commit("updateCurrentUser");
      this.$router.push("/");
    },
  },
  created() {
    this.$store.commit("updateCurrentUser");
  },
};
</script>
