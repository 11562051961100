import Vue from "vue";
import VueRouter from "vue-router";
import usersService from "../services";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      title: "DoA - Home",
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/Signup.vue"),
    meta: {
      title: "DoA - Signup",
    },
  },
  {
    path: "/activate",
    name: "activate",
    component: () => import("../views/Activate.vue"),
    meta: {
      title: "DoA - Activate",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "DoA - Login",
    },
  },
  {
    path: "/password/recover",
    name: "passwordRecover",
    component: () => import("../views/Password/Recover.vue"),
    meta: {
      title: "DoA - Password Recover",
    },
  },
  {
    path: "/password/reset",
    name: "passwordReset",
    component: () => import("../views/Password/Reset.vue"),
    meta: {
      title: "DoA - Password Reset",
    },
  },
  {
    path: "/admin/users",
    name: "adminUsers",
    component: () => import("../views/Admin/Users.vue"),
    meta: {
      title: "DoA - Users",
    },
  },
  {
    path: "/admin/applications",
    name: "adminApplications",
    component: () => import("../views/Review/Applications.vue"),
    meta: {
      title: "DoA - Applications",
    },
  },
  // {
  //   path: '/admin/schedules',
  //   name: 'adminSchedules',
  //   component: () => import('../views/Admin/Schedules.vue'),
  //   meta: {
  //     title: 'DoA - Schedules'
  //   }
  // },
  {
    path: "/review/detail/:id",
    name: "reviewDetail",
    component: () => import("../views/Review/Detail.vue"),
    meta: {
      title: "DoA - Application Detail",
    },
  },
  {
    path: "/review/applications",
    name: "reviewApplications",
    component: () => import("../views/Review/Applications.vue"),
    meta: {
      title: "DoA - Applications",
    },
  },
  // {
  //   path: '/review/schedule',
  //   name: 'reviewSchedule',
  //   component: () => import('../views/Review/Schedule.vue'),
  //   meta: {
  //     title: 'DoA - Schedule'
  //   }
  // },
  {
    path: "/application/info",
    name: "applicationInfo",
    component: () => import("../views/Application/Info.vue"),
    meta: {
      title: "DoA - Apply",
    },
  },
  // {
  //   path: '/application/schedule',
  //   name: 'applicationSchedule',
  //   component: () => import('../views/Application/Schedule.vue'),
  //   meta: {
  //     title: 'DoA - Schedule'
  //   }
  // },
  {
    path: "/upload",
    name: "upload",
    component: () => import("../views/Upload.vue"),
    meta: {
      title: "DoA - Upload",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // set page title every new route
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  next();
});

router.beforeEach((to, from, next) => {
  // redirect to home page if not logged in and trying to access a restricted page
  const publicPages = [
    "/",
    "/signup",
    "/activate",
    "/login",
    "/upload",
    "/password/reset",
    "/password/recover",
  ];
  const authRequired = !publicPages.includes(to.path);
  if (authRequired) {
    const user = usersService.getCurrentUser();
    if (!user) {
      return next("/");
    }
    // admin pages require role
    if (to.path.startsWith("/admin")) {
      if (user.role != "admin" && user.role != "owner") {
        return next("/");
      }
    }
    // application review pages require professor
    if (to.path.startsWith("/review")) {
      if (
        user.role != "professor" &&
        user.role != "admin" &&
        user.role != "owner"
      ) {
        return next("/");
      }
    }
    // application info pages require applicant
    if (to.path.startsWith("/application")) {
      if (user.role != "applicant") {
        return next("/");
      }
    }
  }
  next();
});

export default router;
